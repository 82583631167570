import React from 'react';
import { routes } from '../routes/routes';
import { apiRoutes } from '../api/apiRoutes';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBox from '@mui/icons-material/AccountBox';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import Speed from '@mui/icons-material/Speed';

export const companySidebarItems = [];

export const sidebarItems = [
  {
    title: 'SIDEBAR.GROUP_GENERAL',
    items: [
      {
        title: 'SIDEBAR.DASHBOARD',
        icon: <Speed />,
        url: routes.admin.dashboard,
      },
    ],
  },
  {
    title: 'SIDEBAR.GROUP_SETTINGS',
    items: [
      {
        title: 'SIDEBAR.COMPANIES',
        icon: <BusinessIcon />,
        url: routes.admin.companies.list,
        apiUrl: apiRoutes.companies.list,
        enabled: process.env.REACT_APP_PLATFORM_ENABLED === 'true',
      },
      {
        title: 'SIDEBAR.USERS',
        icon: <AccountBox />,
        url: routes.admin.users.list,
        apiUrl: apiRoutes.users.list,
      },
      {
        title: 'SIDEBAR.TRANSLATIONS',
        icon: <SpellcheckIcon />,
        url: routes.admin.translations.list,
        apiUrl: apiRoutes.translations.list,
      },
    ],
  },
];
