import { baseRoutes } from '../../core/config/routes';

export const routes = {
  ...baseRoutes,
  admin: {
    ...baseRoutes.admin,
  },
  auth: {
    ...baseRoutes.auth,
  },
};
